
@font-face {
  font-family: 'Futura';
  src: url('./fonts/Futura.ttc');
}
@font-face {
  font-family: 'Isidora Sans Light';
  src: url('./fonts/isidorasans-light.otf');
}
@font-face {
  font-family: 'Isidora Sans Regular';
  src: url('./fonts/isidorasans-regular.otf');
}

@font-face {
  font-family: 'Isidora Semi Bold';
  src: url('./fonts/isidorasans-semibold.otf');
}
@font-face {
  font-family: 'Futura Bk BT';
  src: url('./fonts/FuturaBookBT.ttf');
}
@font-face {
  font-family: 'Futura Hv BT';
  src: url('./fonts/FuturaHeavyBT.ttf');
}

@font-face {
  font-family: 'Isidora Sans Black';
  src: url('./fonts/isidorasansalt-black.otf');
}

@font-face {
  font-family: 'Futura BT Light';
  src: url('./fonts/FUTURAL.TTF');
}

@font-face {
  font-family: 'Futura BT Medium';
  src: url('./fonts/Futura_20Medium_20BT.ttf');
}

@font-face {
  font-family: 'Futura Bold';
  src: url('./fonts/Futura Bold.otf');
}

@font-face {
  font-family: 'Futura Bold';
  src: url('./fonts/Futura Bold.otf');
}

@font-face {
  font-family: 'Futura Lt BT Light';
  src: url('./fonts/Futura Lt BT Light.ttf');
}





.center-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end-align {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.flex-start-center-align {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.flex-start-align {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-all-start-align {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-all-end-align {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.magin-top {
  margin-top: 2%;
}

.main-container {
  height: 100vh;
  width: 100vw;
/*   overflow-x: hidden; */
  
}

.main-inside-container {
  height: 100%;
  width: 100%;
  
  
}
.text-align-right {
  text-align: right;
}
@media only screen and (min-width: 1024px) {

  .arrow-svg {
  
  height: 8vh;
  width: auto;
  }

 .arrow-svg-container {
  z-index: 1000;
  position: fixed;
  right: 6vw;
  bottom: 10vh;
 
 /*  position: absolute;
  left: 30px;
  bottom: 50px; */
}

.menu-footer-style {
  font-size:4.2vh;
  color: white;
  font-family: 'Futura Lt BT Light';
  
}
.menu-align {
  margin-top: 6%;

}

.menu-align-1 {
  margin-top: 7%;
}

.menu-align-2 {
  margin-top: 5%;
}

.inside-container {
  margin-top: 5%;
  height: 90%;
  width: 75%;
/*   border: 0.5vh;
  border-style: solid;
  border-color: aqua;  */
}

.main-div-container {
  height: 100vh;
  width: 100vw;
/*   overflow-y: auto;
  overflow-x: hidden; */
  
}

.main-div-menu-container {
  height: 100vh;
  width: 100vw;
/*   overflow: hidden; */

  background-color: white;

}

.inside-div-container {
  height: 100%;
  width: 80%;
  
}

.inside-div-container-1 {
  height: 100%;
  width: 80%;
    border: 0.5vh;
  border-style: solid;
  border-color: aqua;  
}

.inside-div-container-1-clinics-title {
  height: 100%;
  width: 92%;
 /*   border: 0.5vh;
  border-style: solid;
  border-color: aqua;  */
}

.inside-div-container-1-clinics {
 
  height: 100%;
  width: 45.5%;
 /*  border: 0.5vh;
  border-style: solid;
  border-color: aqua;   */
}

.inside-div-container-1-reviews-title {
  height: 100%;
  width: 92%;
  /*   border: 0.5vh;
  border-style: solid;
  border-color: aqua;   */
}

.inside-div-container-1-reviews {
  height: 100%;
  width: 35vw;
 /*   border: 0.5vh;
  border-style: solid;
  border-color: blue;  */ 
}

.inside-div-container-2-reviews {
  height: 100%;
  width:56vw;
  /*   border: 0.5vh;
  border-style: solid;
  border-color: blueviolet;   */
}

.reviews-content-text {
    font-family: 'Futura BT Medium';

	  font-size: 2.7vh;
    /*text-align: justify;*/
   
    line-height: 1.3em;
    color:#6c6c6c;
}

.inside-div-container-2-body {
  height: 40%;
  width: 100%;
     border: 0.5vh;
  border-style: solid;
  border-color: aqua;  
}



.inside-div-container-2 {
  height: 90%;
  width: 100%;
     border: 0.5vh;
  border-style: solid;
  border-color: aqua;  
}




.first-container {
  background-color: white;
 height: 100vh;
 width: 100vw;
 /*   border: 0.5vh;
 border-style: solid;
 border-color: aqua;   */
 
}

.first-container-cover {
  height: 100vh;
  width: 100vw;
  /*  border: 0.5vh;
 border-style: solid;
 border-color: aqua;   */
 
}

.first-container-footer {
 height: 100vh;
 width: 100vw;
/*   border: 0.5vh;
 border-style: solid;
 border-color: aqua;  */
 
}

.first-container-content {
   background-color: #228dcc;
   width: 100vw;
}








.first-container-video {
 
   width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
   z-index: -1;  
 
}

.first-container-video-1 {
 
   width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
   z-index: -1;  
 
}

.video-style {
   height: auto;
   width: 100%;
   object-fit: cover;
   
}

.main-background-color {
  background-color: #228dcc;
}
.main-background-color-white {
   background-color: white;
}

.main-background-color-secondary {
  background-color: #027aeb;
}
.font-color { 
   color: #228dcc;
}



.main-content-container {
  margin-top: 3vh;
  height: 100%;
  width: 100vw;
 
  display: flex;
/*   border: 0.5vh;
  border-style: solid;
  border-color: palegreen;   */
  
}

.main-inside-content-container {
   height: 100%;
   width: 90%;
/*   border: 0.5vh;
  border-style: solid;
  border-color: blueviolet;   */
  
}

.main-content-container-review {
 
  height: 110vh;
  width: 100vw;

  display: flex;
/*    border: 0.5vh;
  border-style: solid;
  border-color: palegreen;  */
  
}



.inside-content-container-1 {
  height: 100%;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  
 /*  border: 0.5vh;
  border-style: solid;
  border-color: palegreen; */
 
}

.inside-content-container-2 {
  height: 100%;
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  /*  border: 0.5vh;
  border-style: solid;
  border-color: palegreen; */
  
}

.inside-content-container-3 {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
 /*   border: 0.5vh;
  border-style: solid;
  border-color: palegreen;
 */
}

.inside-content-container-4 {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

/*  border: 0.5vh;
  border-style: solid;
  border-color: palegreen;  */
  
}

.inside-content-container-5 {
  height: 18%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

/*   border: 0.5vh;
  border-style: solid;
  border-color: palegreen;   */
  
}

.inside-content-container-6 {
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

/*   border: 0.5vh;
  border-style: solid;
  border-color: palegreen;  */
  
}

.inside-content-container-7 {
  height: 38%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

/*   border: 0.5vh;
  border-style: solid;
  border-color: palegreen;  */
  
}

.inside-content-container-8 {
  height: 69%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  /* border: 0.5vh;
  border-style: solid;
  border-color: palegreen;   */
  
}

.clinic_content_inside_image {
  margin-top: 2%;
  height: 50vh;
  width: auto;
}
.review_content_inside_image {
  height: 70vh;
  width: auto;
}




.inside-wrapper-content {
  height: 100%;
  width: 100%;
/*    border: 0.5vh;
  border-style: solid;
  border-color: palegreen;  */
}

.inside-wrapper-content-1 {
  height: 100%;
  width: 80%;
  /*  border: 0.5vh;
  border-style: solid;
  border-color: palegreen; */ 
}

.inside-wrapper-content-3 {
  margin-top: 1%;
  
  width: 48%;
/*    border: 0.5vh;
  border-style: solid;
  border-color: black;  */
}


.inside-wrapper-content-3-review {
  margin-top: 1%;
 


  width: 45vw;
/*    border: 0.5vh;
  border-style: solid;
  border-color: black;  */
}

.top-inside-content {
  height: 20%;
  width: 100%;

/*   border: 0.5vh;
  border-style: solid;
  border-color: palegreen; */
}

.bottom-inside-content {
  height: 80%;
  width: 100%;

/*   border: 0.5vh;
  border-style: solid;
  border-color: blue; */
}



.img-content-cover {
  height: auto;
  width: 22vw;
}
.inside-content-wrapper {
  margin-top: 15%;
  margin-left: 10vw;
}

.label-content-container {
   margin-top: 5%;
	 color: #138ECD;
   font-family: 'Isidora Sans Regular';
   font-size: 8vh;
   line-height: 1em;
   
}

.content {
    font-family: 'Futura Bk BT';
    margin-top: 10%;
    width: 80%;
	  font-size: 3.2vh;
    /*text-align: justify;*/
   
    line-height: 1.3em;
    color:#6c6c6c;
}

.location-container {
  
  height: 85vh;
  width: 86%;
  /*background-color: #6c6c6c;*/
}

.location-inside-container-1 {
    height:80%;
    width:100%;
 /*    border: 0.5vh;
    border-style: solid;
    border-color: aqua;  */
}

.menu-inside-container-1 {
   height:100%;
   width:50%;
   margin: 1%;
 /*   border: 0.5vh;
   border-style: solid;
   border-color: aqua;  */
}

.location-inside-container-2 {
    margin-top: 1%;
    width:100%;
   /*  border: 0.5vh;
    border-style: solid;
    border-color: aqua; */
}
.location-wrapper-container-1 {
   height: 100%;
   width: 50%;

}

.location-box {
  height: 20%;
  width: 20%;

}

.location-wrapper-container {
 
  height: 100%;
  width: 33%;
 
}
.location-content-container {
  height: 50%;
  width: 100%;
/*   border: 0.5vh;
    border-style: solid;
    border-color: aqua;  */
}
.location-content-container-1 {
  height: 90%;
  width: 90%;

}

.lbl-city {
  margin-top: 15%;
  width: 100%;
  font-size: 3.8vh;
	font-family: 'Futura Hv BT';
  color: white;
}

.lbl-address {
  margin-top: 3%;
  width: 100%;
  font-family: 'Futura Bk BT';
  font-size: 2.9vh;
	letter-spacing: 1px;
	line-height:3.5vh;
	font-weight: 100;
  color: white;
}
.logo-container {
  height: 100%;
  width: 100%;
}

.line {

  height: 0.3vh;
  width: 100%;
  background-color: white;
}

.image_logo_white {
  margin-top: 3%;
  height: 9vh;
  width: auto;
}
.logo-image-container {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
 /*  border: 0.5vh;
  border-style: solid;
   border-color: aqua; */
}

.image_hamburger_menu {
    height: 5vh;
  width: auto;
   margin-right: 6vw;
   margin-bottom: 1vh;
   
}

.image_hamburger_menu_orig {
   height: 5vh;
   width: auto;

   
}

.image_content {
  height: auto;
  width: 100%;
  object-fit: contain;
  margin-top: -6%;
}






.hamburger-container {
  height: 15vh;
  width: 100vw;
  /* border: 0.5vh;
  border-style: solid;
  border-color: aqua; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

}

.menu-container {
   
  height: 100%;
  width: 50%;
 /*   border: 0.5vh;
  border-style: solid;
  border-color: aqua;  */
}

.menu-text-content {
  font-family: 'Futura Lt BT Light';
  font-size: 4.5vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 11%;
  width: 80%;
 /*  border: 0.5vh;
  border-style: solid;
  border-color: aqua; */
}

.socmed_logo {
  height: 6vh;
  width: auto;
}
.socmed_logo_align {
  margin-left: 2vw;
}


.content-title-text {

  height: 50%;
  width: 80%;
  font-family: 'Isidora Sans Black';
  font-size: 7vh;
  color: gray;
 /*  border: 0.5vh;
  border-style: solid;
  border-color: blue; */
}

.content-inside-container {
  height: 60%;
  width: 100%;

/*    border: 0.5vh;
  border-style: solid;
  border-color: blue;   */ 
}


.content-inside-container-new {
  height:100%;
  width: 50%;

 /*    border: 0.5vh;
  border-style: solid;
  border-color: blue;    */
}


.content-inside-container-new-premium {
  height:80%;
  width: 50%;

/*     border: 0.5vh;
  border-style: solid;
  border-color: blue;    */
}





.content-inside-container-1 {
  height: 30%;
  width: 100%;

 /*   border: 0.5vh;
  border-style: solid;
  border-color: blue;  */
}

.content-inside-container-1-premium {
  height: 35%;
  width: 100%;

 /*   border: 0.5vh;
  border-style: solid;
  border-color: blue;  */
}
.content-header-text {
   font-size: 3.3vh;
	font-family: 'Futura Bk BT';
  color: #1274cd;
  margin-right: 5vw;
}

.content-label-text {
 font-size: 2.8vh;
	font-family: 'Futura Bk BT';
  color: gray;
}


.content-header-text-premium {
   font-size: 2.6vh;
	font-family: 'Futura Bk BT';
  color: #1274cd;
  margin-right: 4vw;
}

.content-label-text-premium {
 font-size: 2.3vh;
	font-family: 'Futura Bk BT';
  color: gray;
}



.content-header-text-laser {
  font-size: 2.7vh;
	font-family: 'Futura Bk BT';
  color: #1274cd;
  margin-right: 5vw;
}

.content-label-text-laser {
 font-size: 2.5vh;
	font-family: 'Futura Bk BT';
  color: gray;
}

.content-label-text-1 {
 font-size: 2.5vh;
	font-family: 'Futura Bk BT';
  color: gray;
}

.inside-wrapper-title-content {

  height: 13%;
  width: 100%;
/*   border: 0.5vh;
  border-style: solid;
  border-color: blue;   */
 
  font-family: 'Futura Bold';
  font-size: 8vh;
  color: gray;
}

.inside-div-content-body {
  height: 23%;
  width: 100%;
/*   border: 0.5vh;
 
  border-style: solid;
  border-color: blue;  */
  font-size: 3.6vh;
  font-family: 'Futura BT Light'; 
  color: gray;
}

.inside-div-content-laser {
  height: 17%;
  width: 100%;
/*   border: 0.5vh;
 
  border-style: solid;
  border-color: blue; */ 
  font-size: 3.6vh;
  font-family: 'Futura BT Light'; 
  color: gray;
}

.inside-div-content-premium {
  height: 16%;
  width: 100%;
  border: 0.5vh;
 
/*   border-style: solid;
  border-color: blue;  */
  font-size: 3vh;
  font-family: 'Futura BT Light'; 
  color: gray;
}


.inside-div-price-body {
  height: 52%;
  width: 100%;
/*   border: 0.5vh;
  border-style: solid;
  border-color: blue; */ 
}
.inside-div-price-1 {
  height: 100%;
  width: 50%;
/*   border: 0.5vh;
  border-style: solid;
  border-color: orange;  */
}

.content-div-price {
   height: 21vh;
  width: 100%;
/*   border: 0.5vh;
  border-style: solid;
  border-color: red;  */
}

.content-div-price-face {
  height: 26vh;
  width: 100%;
 /*  border: 0.5vh;
  border-style: solid;
  border-color: red;  */
}

.content-div-price-laser {
  height: 21vh;
  width: 100%;
 /*  border: 0.5vh;
  border-style: solid;
  border-color: red;  */
}


.content-div-price-premium {
  height: 17vh;
  width: 100%;
/*   border: 0.5vh;
  border-style: solid;
  border-color: red;  */
}

.header-text {

  font-family: 'Futura Bold';
  font-size: 8vh;
  color: gray;
}

.clinic-container {
  margin-top: 2%;

 /*  height: 100%; */
  width: 100%;
 /*  border: 0.5vh;
  border-style: solid;
  border-color: blue; */
}

.clinic-text-header {
  font-size: 2.5vh;
  font-family: 'Futura BT Medium';
  color: #138ECD;
}
.clinic-content-header {
/*   margin-top: 1%; */
  font-size: 2.3vh;
  font-family: 'Futura BT Medium';
  color: gray;
}
}


@media only screen and (max-width: 1024px) {

  .arrow-svg {
  
  height: 6vh;
  width: auto;
}

.arrow-svg-container {
  z-index: 1000;
  position: fixed;
  right: 6vw;
  bottom: 22vh;
 
 /*  position: absolute;
  left: 30px;
  bottom: 50px; */
}

  .menu-margin-top {
    margin-top: 5%;
  }
.review_content_inside_image {
  height: 21vh;
  width: auto;
}
.clinic_content_inside_image {
  height: auto;
  width: 80vw;
}
  .menu-footer-style {
  font-size: 3.8vh;
  color: white;
  font-family: 'Futura Lt BT Light';
  
}
.menu-align {
  margin-top: 10%;
}

.menu-align {
  margin-top: 4.8vh;

}

.menu-align-1 {
  margin-top: 20%;
}

.menu-align-2 {
  margin-top: 15%;
}

.inside-container {
  margin-top: 5%;
  height: 90%;
  width: 75%;
/*    border: 0.5vh;
  border-style: solid;
  border-color: aqua;  */
}

.main-div-container {
  height: 100vh;
  width: 100vw;
 /*  overflow: auto;
  overflow-x: hidden; */
  scroll-behavior: smooth;
}

.inside-div-container {
  height: 100%;
  width: 80%;
}

.inside-div-container-1 {
  height: 100%;
  width: 80%;
/*    border: 0.5vh;
  border-style: solid;
  border-color: aqua;  */
}

.inside-div-container-1-mobile {
  height: 21vh;
  width: 80%;
   /*  border: 0.5vh;
  border-style: solid;
  border-color: aqua;   */
}


.inside-div-container-2 {
  height: 90%;
  width: 100%;
   /*  border: 0.5vh;
  border-style: solid;
  border-color: aqua;  */
}


.first-container {
 background-color: white;
 width: 100vw;
/*   border: 0.5vh;
 border-style: solid;
 border-color: aqua;  */
 
}

.first-container-1 {
 height: 100vh;
 width: 100vw;
/*   border: 0.5vh;
 border-style: solid;
 border-color: aqua;  */
 
}

.first-container-mobile {
 background-color: white;
 width: 100vw;
/*   border: 0.5vh;
 border-style: solid;
 border-color: aqua;  */
 
}

.first-container-cover {
height: 180vw;
 width: 100vw;
/*   border: 0.5vh;
 border-style: solid;
 border-color: aqua;  */
 
}

.first-container-video {
 
   width: 100vw;
  height: 100vh;
   object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
   z-index: -1;  
 
}

.first-container-video-1 {
 
   width: 110vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
   z-index: -1;  
 
}



.video-style {
   height: auto;
   width: 100%;
    object-fit: cover;
   
}

.main-background-color {
  background-color: #228dcc;
}
.main-background-color-white {
   background-color: white;
}

.main-background-color-secondary {
  background-color: #027aeb;
}
.font-color { 
   color: #228dcc;
}

.font-color-1 { 
   color: white;
}


.main-content-container {

  /* height:200vh; */
  width: 100vw;
 
 /* display: flex;
    border: 0.5vh;
  border-style: solid;
  border-color: palegreen; */
  
}

.main-content-container-mobile-landing {
 /*  height: 200vh; */
  width: 100vw;
 
  
/*    border: 0.5vh;
  border-style: solid;
  border-color: palegreen;  */
}

.main-content-container-mobile-body {
 /*  height: 140vh; */
  width: 100vw;
 
  
   border: 0.5vh;
  border-style: solid;
  border-color: transparent; 
  
}

.main-content-container-mobile-laser {
  /* height: 155vh; */
  width: 100vw;
 
  
   border: 0.5vh;
  border-style: solid;
  border-color: transparent; 
  
}

.main-content-container-mobile-face {
  
  width: 100vw;
 
  
   border: 0.5vh;
  border-style: solid;
  border-color: transparent; 
  
}

.main-content-container-mobile-premium {
  /* height: 240vh; */
  width: 100vw;
 
  
   border: 0.5vh;
  border-style: solid;
  border-color: transparent; 
  
}


.inside-content-container-1 {
  height: 35%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
 /*  border: 0.5vh;
  border-style: solid;
  border-color: palegreen;  */
 
}

.inside-content-container-2 {
  margin-top: 6%;
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

 /*   border: 0.5vh;
  border-style: solid;
  border-color: palegreen;   */
  
}

.inside-content-container-3 {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  
/*    border: 0.5vh;
   border-style: solid; 
   border-color: palegreen;  */
 
}

.inside-content-container-4 {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

/*  border: 0.5vh;
  border-style: solid;
  border-color: palegreen;  */
  
}

.inside-content-container-5 {
  height: 12vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

   /* border: 0.5vh;
  border-style: solid;
  border-color: palegreen;  */ 
  
}

.inside-content-container-6 {
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

/*   border: 0.5vh;
  border-style: solid;
  border-color: palegreen;  */
  
}

.inside-content-container-7 {
  height: 38%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

/*   border: 0.5vh;
  border-style: solid;
  border-color: palegreen;  */
  
}


.inside-content-container-reviews-image {
  height: 25vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  
   /* border: 0.5vh;
  border-style: solid;
  border-color: palegreen;  */ 
  
}

.inside-background-reviews {
  height: 22vh;
  width: 80%;
  background-image: url("./assets/review_content_inside_image_mobile.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  
}




.inside-wrapper-content {
  height: 160vh;
  width: 100%;
   /*  border: 0.5vh;
  border-style: solid;
  border-color: palegreen;  */ 
}

.inside-wrapper-content-clinic {
  height: 100vh;
  width: 100%;
   /*  border: 0.5vh;
  border-style: solid;
  border-color: palegreen;  */ 
}

.inside-wrapper-content-review {
  height: 170vh;
  width: 100%;
   /*  border: 0.5vh;
  border-style: solid;
  border-color: palegreen;  */ 
}

.inside-wrapper-content-1 {
  height: 100%;
  width: 70%;
  /*  border: 0.5vh;
  border-style: solid;
  border-color: palegreen; */ 
}

.inside-wrapper-content-2 {

  height: 100%;
  width: 86%;
  /*  border: 0.5vh;
  border-style: solid;
  border-color: palegreen; */ 
}


.inside-wrapper-content-3 {
  margin-top: 1%;
 
  width: 48%;
/*    border: 0.5vh;
  border-style: solid;
  border-color: black;  */
}

.inside-wrapper-content-3-review {
 margin-top: 20vh; 
  height: 70vh;
  width: 100%;
/*    border: 0.5vh;
  border-style: solid;
  border-color: black;  */
}

.top-inside-content {
  height: 20%;
  width: 100%;

/*   border: 0.5vh;
  border-style: solid;
  border-color: palegreen; */
}

.bottom-inside-content {
  height: 80%;
  width: 100%;

/*   border: 0.5vh;
  border-style: solid;
  border-color: blue; */
}



.img-content-cover {
  height: 40vh;
  width: auto;
}
.inside-content-wrapper {
  margin-top: 15%;
  width: 80%;
/*   margin-top: 15%;
  margin-left: 18vw; */
}

.label-content-container {
   margin-top: 5%;
	 color: #138ECD;
   font-family: 'Isidora Sans Regular';
   font-size: 8vh;
   line-height: 1em;
   
}

.content {
    font-family: 'Futura Bk BT';
    /*  margin-bottom: 20%; */
    width: 80%;
	  font-size: 2.8vh;
    /*text-align: justify;*/
   
    line-height: 1.3em;
    color:#6c6c6c;
}

.location-container {
  height: 95vh; 
  width: 100%;

  
}

.location-inside-container-1 {
    height:70%;
    width:100%;
   /*   border: 0.5vh;
    border-style: solid;
    border-color: aqua;  */
}

.location-inside-container-2 {
   margin-top: 5%;
    width:100%;
   /*  border: 0.5vh;
    border-style: solid;
    border-color: aqua; */
}
.location-wrapper-container-1 {
   height: 100%;
   width: 50%;

}

.location-box {
  height: 20%;
  width: 20%;

}

.location-wrapper-container {
 
  height: 100%;
  width: 33%;
 
}
.location-content-container {
  height: 50%;
  width: 100%;
/*   border: 0.5vh;
    border-style: solid;
    border-color: aqua;  */
}
.location-content-container-1 {
  height: 90%;
  width: 90%;

}

.lbl-city {
  margin-top: 15%;
  width: 100%;
  font-size: 3.5vh;
	font-family: 'Futura Hv BT';
  color: white;
}

.lbl-address {
  margin-top: 3%;
  width: 100%;
  font-family: 'Futura Bk BT';
  font-size: 2.6vh;
	letter-spacing: 1px;
	line-height:3.5vh;
	font-weight: 100;
  color: white;
}
.logo-container {
  height: 100%;
  width: 100%;
}

.line {

  height: 0.3vh;
  width: 100%;
  background-color: white;
}

.image_logo_white {
  margin-top: 12%;
  height: 10vh;
  width: auto;
}
.logo-image-container {
  margin-top: 2vh;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url("./assets/logo-white.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
 /*  border: 0.5vh;
  border-style: solid;
   border-color: aqua; */
}

.image_hamburger_menu {
   height: 4vh;
   width: auto;
   margin-right: 6vw;
   margin-bottom: 1vh;
   
}

.image_hamburger_menu_orig {
   height: 4vh;
   width: auto;

   
}

.image_content {
  height: auto;
  width: 100%; 
  object-fit: cov;
}



.hamburger-container {
  height: 15vh;
  width: 100vw;
  /* border: 0.5vh;
  border-style: solid;
  border-color: aqua; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

}

.menu-container {
   
  height: 100%;
  width: 45%;
 /*  border: 0.5vh;
  border-style: solid;
  border-color: aqua; */
}

.menu-text-content {
  font-family: 'Futura Lt BT Light';
  font-size: 4vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 12%;
  width: 80%;
 /*  border: 0.5vh;
  border-style: solid;
  border-color: aqua; */
}

.socmed_logo {
  height: 5vh;
  width: auto;
}
.socmed_logo_align {
  margin-left: 5vw;
}


.content-title-text {

  height: 50%;
  width: 80%;
  font-family: 'Isidora Sans Black';
  font-size: 7vh;
  color: gray;
 /*  border: 0.5vh;
  border-style: solid;
  border-color: blue; */
}

.content-inside-container {
  height: 100%;
  width: 42%;

 /*   border: 0.5vh;
  border-style: solid;
  border-color: blue;  */
}

.content-inside-container-mobile {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 4vh;
  width: 100%;

 /*    border: 0.5vh;
  border-style: solid;
  border-color: blue;   */
}

.content-white-space {
  height: 5vh;
  width: 100%;
}



.content-inside-container-mobile-face {
 
}


.content-inside-container-mobile-body {
 margin-top: 5vh;
  width: 100%;

  /*   border: 0.5vh;
  border-style: solid;
  border-color: blue;   */
}



.content-inside-container-1 {
  height: 30%;
  width: 100%;

 /*   border: 0.5vh;
  border-style: solid;
  border-color: blue;  */
}
.content-header-text {
  font-size: 3vh;
	font-family: 'Futura Bk BT';
  color: #1274cd;
}

.content-label-text {
 font-size: 2.8vh;
	font-family: 'Futura Bk BT';
  color: gray;
}

.content-label-text-1 {
 font-size: 2.5vh;
	font-family: 'Futura Bk BT';
  color: gray;
}

.inside-wrapper-title-content {

  margin-top: 5%;
  height: 7vh;
  width: 80%;
 /*  border: 0.5vh;
  border-style: solid;
  border-color: blue;   */
  margin-left: 7vw;
  font-family: 'Futura Bold';
  font-size: 4vh;
  color: gray;
}

.header-text {
  font-family: 'Futura Bold';
  font-size: 5vh;
  color: gray;
}

.clinic-container {
   margin-top: 7%;
 /*  margin-top: 4%;
  height: 80%; */
  width: 100%;
/*     border: 0.5vh;
  border-style: solid;
  border-color: blue;  */ 
}

.clinic-container-image {
  margin-top: 8vh;
   height: 90%;
  width: 100%;
/*     border: 0.5vh;
  border-style: solid;
  border-color: blue;   */
}

.clinic-text-header {
  font-size: 2.8vh;
  font-family: 'Futura BT Medium';
  color: #138ECD;
}
.clinic-content-header {
 
  font-size: 2vh;
  font-family: 'Futura BT Medium';
  color: gray;
}

.location-mobile-container {
  height: 18%;
  width: 100%;
 /*  border: 0.5vh;
  border-style: solid;
  border-color: blue; */
}

.location-mobile-logo-container {
  

  height: 12vh;
  width: 100%;
/*    border: 0.5vh;
  border-style: solid;
  border-color: blue;   */
}
  .location-footer-mobile-container {
   
    margin: 10%;

    height: 64vh;
    width: 80%;
 /*     border: 0.5vh;
  border-style: solid;
  border-color: aqua;   */
  }
  .inside-div-content-mobile {
   
  width: 80%;
 /*  border: 0.5vh;
  border-style: solid;
  border-color: blue;  */
  font-size: 3.2vh;
    font-family: 'Futura BT Light'; 
    color: gray;
    margin-left: 7vw;
    margin-bottom: 4vh;
  }
  

.main-content-container-review {
 
/*   height: 110vh; */
 
 
  display: flex;
/*    border: 0.5vh;
  border-style: solid;
  border-color: palegreen;  */
  
}

.main-div-menu-container {
  height: 100vh;
  width: 100vw;
/*   overflow: hidden; */

  background-color: white;

}



}